<template>
  <div class="content">
    <div class="title">新增</div>
    <el-form :model="Form" label-width="100px" class="form">
      <el-row>
        <el-col :span="8">
          <el-form-item label="活动名称">
            <el-input v-model="Form.user" placeholder="审批人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动地点">
            <el-input v-model="Form.user" placeholder="审批人"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="活动开始时间">
            <el-input v-model="Form.user" placeholder="审批人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动结束时间">
            <el-input v-model="Form.user" placeholder="审批人"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="活动开始时间">
            <el-input v-model="Form.user" placeholder="审批人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动结束时间">
            <el-input v-model="Form.user" placeholder="审批人"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Form: {
        user: "",
        region: "",
      },
    };
  },
  methods: {
    onSubmit() {},
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;
  box-sizing: border-box;
  padding: 20px 20px;
  .title {
    text-align: left;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .el-row {
    display: flex;
    justify-content: space-around;
    padding: 0 100px;
  }
  /deep/ .el-form-item {
    /deep/ .el-form-item__content {
      text-align: center !important;
    }
  }
}
</style>